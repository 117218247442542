import React, { createContext, useState, useEffect } from "react";
import { PriceDataT } from "../util/types";
import useRemoteConfig from "../hooks/useRemoteConfig";
import { REMOTE_CONFIG_STRING_KEYS } from "../services/firebase-remote-config";
import { setMixpanelProperties } from "../services/mixpanel/mixpanel";
import { GeolocationI, getGeolocation } from "../services/ipgeolocation";
import {
  US_PRICE_WEB,
  US_PRICE_WEB_SPECIAL_DISCOUNT,
  US_PRICE_WEB_TEST,
  getPriceDataFromLocation,
  getPriceDataFromLocationWithSpecialDiscount,
} from "../util/static-price-data";
import { sendSlackErrorMessage } from "../services/slack";
import { getUserEmail, getUserUID } from "../services/firebase";

type LocalPriceContextType = {
  currentPrice: PriceDataT;
  location: GeolocationI | null;
  specialDiscount: boolean;
  setSpecialDiscount: (specialDiscount: boolean) => void;
};

const defaultLocalPriceContext: LocalPriceContextType = {
  currentPrice: US_PRICE_WEB,
  location: null,
  specialDiscount: false,
  setSpecialDiscount: () => null,
};

export const LocalPriceContext = createContext<LocalPriceContextType>(defaultLocalPriceContext);

export const LocalPriceProvider = ({
  children,
  specialDiscount: initialSpecialDiscount = false,
}: any) => {
  const [variant, useDefault] = useRemoteConfig(REMOTE_CONFIG_STRING_KEYS.stripePriceTest);
  const [location, setLocation] = useState<GeolocationI | null>(defaultLocalPriceContext.location);
  const [specialDiscount, setSpecialDiscount] = useState(initialSpecialDiscount);

  const [currentPrice, setCurrentPrice] = useState<PriceDataT>(
    defaultLocalPriceContext.currentPrice,
  );

  const setLocalizedPrice = async () => {
    if (useDefault) {
      return;
    }

    // const inTest = variant === "test";
    // temp pause test for embeddables
    const inTest = false;
    const mixpanelPriceTest = {
      "202407WebPriceTest_2": inTest ? "Test162Price" : "Control125Price",
    };
    try {
      const userLocation = await getGeolocation();
      setLocation(userLocation);
      setCurrentPrice(
        specialDiscount
          ? getPriceDataFromLocationWithSpecialDiscount(userLocation)
          : getPriceDataFromLocation(userLocation, inTest),
      );
      setMixpanelProperties(
        {
          ...(userLocation.is_eu ? {} : { ...mixpanelPriceTest }),
          "Country Code": userLocation.country_code2,
        },
        true,
      );
    } catch (err) {
      setCurrentPrice(
        specialDiscount ? US_PRICE_WEB_SPECIAL_DISCOUNT : inTest ? US_PRICE_WEB_TEST : US_PRICE_WEB,
      );
      setMixpanelProperties(mixpanelPriceTest, true);
      console.error(err);
      await sendSlackErrorMessage(
        `IP Geolocation API error. ${getUserEmail() ?? ""} ${getUserUID() ?? ""} ${err}`,
      );
    }
  };

  // Set localized price if can fetch user's location.
  useEffect(() => {
    if (variant || specialDiscount) {
      setLocalizedPrice();
    }
  }, [variant, specialDiscount]);

  return (
    <LocalPriceContext.Provider
      value={{ currentPrice, location, specialDiscount, setSpecialDiscount }}
    >
      {children}
    </LocalPriceContext.Provider>
  );
};
