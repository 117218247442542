import HeroMobile from "../content/img/homepage/main-variant-mobile.png";
import HeroDesktop from "../content/img/homepage/main-variant-desktop.png";
import AnxiousDesktop from "../content/img/homepage/anxious-desktop.png";
import AnxiousMobile from "../content/img/homepage/anxious-mobile.png";
import HabitsDesktop from "../content/img/homepage/habits-desktop.png";
import HabitsMobile from "../content/img/homepage/habits-mobile.png";
import MediaDesktop from "../content/img/homepage/social-media-desktop.png";
import MediaMobile from "../content/img/homepage/social-media-mobile.png";
import Brain from "../content/img/homepage/brain.png";
import Lightbulb from "../content/img/homepage/lightbulb.png";
import Rocket from "../content/img/homepage/rocket.png";
import StackedShapes from "../content/img/homepage/stacked-shapes.png";
import BrainHold from "../content/img/homepage/brain-hold.png";
import HeadWithLines from "../content/img/homepage/head-with-lines.png";
import HugArms from "../content/img/homepage/hug-arms.png";
import ShapesBlueOrangeYellow from "../content/img/homepage/shapes-blue-orange-yellow.png";
import BlueRocketHand from "../content/img/homepage/blue-rocket-hand.png";
import HandToHeart from "../content/img/homepage/hand-to-heart.png";
import PhoneInHand from "../content/img/homepage/phone-in-hand.png";
import Target from "../content/img/homepage/target.png";

/* eslint-disable quote-props */
export const LP_VARIANT_MAPPING = {
  "1": {
    experiment: "interestingPersonLP",
    mainTitle: "Doomscrolling isn’t a hobby. Become a more interesting person.",
    subTitle: "Find out how you can stand out from the crowd.",
    heroImgDesktop: HeroDesktop,
    heroImgMobile: HeroMobile,
    testimonialHeader: "Captivate others with your knowledge",
    testimonials: [
      "“I used to habitually open up social media…Not any more! Now, I’m learning about psychology, economics and self-care.”",
      "“Imprint has bred a new sense of curiosity in me and made it so much easier to explore new topics and ideas.”",
      "“Instead of scrolling forever with social media, I have been getting on Imprint and growth scrolling.”",
    ],
    valuePropsHeader: "Drive the conversation",
    valueProps: [
      {
        img: Brain,
        headline: "Get inspired",
        text: "Discover topics that you care about",
      },
      {
        img: Rocket,
        headline: "Fuel your curiosity",
        text: "Never run out of ideas to discuss",
      },
      {
        img: StackedShapes,
        headline: "Master conversation and storytelling",
        text: "Engage others with confidence",
      },
      {
        img: Lightbulb,
        headline: "Stay current",
        text: "Keep up with the world around you",
      },
    ],
    courseHeader: "Find your passion",
  },
  "2": {
    experiment: "anxiousLP",
    mainTitle: "Screen time shouldn’t leave you anxious. <br/><strong>Let’s change that.</strong>",
    subTitle: "Join millions of people who’ve swapped doomscrolling for self-care.",
    heroImgDesktop: AnxiousDesktop,
    heroImgMobile: AnxiousMobile,
    testimonialHeader: "Trade social media for a happier, more enriched mind",
    testimonials: [
      "“The time I spent doom scrolling has been replaced with Imprint…I always feel better leaving the app than when I went in.”",
      "“Open Imprint instead of social media and you’ll be a happier person.”",
      "“This app has really helped me gather my thoughts and ground me.”",
    ],
    valuePropsHeader: "Screen time that leaves you feeling better",
    valueProps: [
      {
        img: HeadWithLines,
        headline: "Designed to replace social media",
        text: "Engaging so you get hooked on self-care",
      },
      {
        img: HugArms,
        headline: "Topics that inspire, not depress",
        text: "Explore topics that you care about",
      },
      {
        img: ShapesBlueOrangeYellow,
        headline: "Focus on what you can control",
        text: "Improve yourself one bite-sized session at a time",
      },
      {
        img: BrainHold,
        headline: "Put your mind at ease",
        text: "Discover the science and history behind better living",
      },
    ],
    courseHeader: "Perspectives you won’t find on social media",
  },
  "3": {
    experiment: "habitsLP",
    mainTitle: "Break the social media cycle. Rediscover healthy habits.",
    subTitle: "Go back to before the algorithm took over your screen time.",
    heroImgDesktop: HabitsDesktop,
    heroImgMobile: HabitsMobile,
    testimonialHeader: "Join millions who’ve hit reset on their phone usage",
    testimonials: [
      "“Before Imprint, I scrolled on social media way too much. This has been an amazing stepping stone to building healthier habits.”",
      "“I used to habitually open up social media…Not any more! I’m learning about psychology, economics and how I can better self-care for myself.”",
      "“Imprint has replaced my doom scrolling. I find myself craving time with this app.”",
    ],
    valuePropsHeader: "Designed to get you back on track",
    valueProps: [
      {
        img: HeadWithLines,
        headline: "Stay engaged",
        text: "Content that keeps you coming back",
      },
      {
        img: HandToHeart,
        headline: "Learn how to form healthy habits",
        text: "Expert knowledge on productivity and habit formation",
      },
      {
        img: ShapesBlueOrangeYellow,
        headline: "Be intentional with your time",
        text: "Build discipline to stop unhealthy screen time",
      },
      {
        img: BlueRocketHand,
        headline: "Anywhere, anytime",
        text: "Easy to fit into your day",
      },
    ],
    courseHeader: "Courses that will inspire a healthier <strong>You</strong>",
  },
  "4": {
    experiment: "focusLP",
    mainTitle: "Social media shrunk your attention span. <strong>Get your focus back.</strong>",
    subTitle: "Join millions who have improved their concentration by learning on Imprint.",
    heroImgDesktop: MediaDesktop,
    heroImgMobile: MediaMobile,
    testimonialHeader: "Your phone doesn’t need to be a distraction",
    testimonials: [
      "“This is a ridiculously great app for those who get distracted easily. My brain LOVES learning on Imprint and I never lose focus!”",
      "“This app makes it easier for my struggling brain to focus on information and stay in the moment.”",
      "“Imprint has replaced my doom scrolling. I find myself craving time with this app.”",
    ],
    valuePropsHeader: "Engineered for deep focus",
    valueProps: [
      {
        img: Brain,
        headline: "Bite-sized lessons",
        text: "Easy-to-digest without getting distracted",
      },
      {
        img: PhoneInHand,
        headline: "Stay engaged",
        text: "Beautiful visuals hold your attention",
      },
      {
        img: Target,
        headline: "Right to the insights",
        text: "Ideas broken down clearly and effectively",
      },
      {
        img: StackedShapes,
        headline: "Active Learning",
        text: "Interactive lessons and games sustain focus",
      },
    ],
    courseHeader: "See what you can learn with your new focus",
  },
};
